* {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  color: white;
}

/***************************/
/* Navbar               */
/***************************/

.navbar {
  margin: 0;
  padding: 1rem 1rem;
  gap: 1rem;
  line-height: 1.0;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-between;
  /* align-items: stretch; */
}

.navbar .navbar-title {
  margin: 0;
  padding: .25rem;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 2rem;
  font-weight: bold;
}

.navbar ul {
  margin: 0;
  padding: 0;
  gap: .75rem;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  align-items: center;
  list-style: none;
}

.navbar ul li {
  margin: 0;
  padding: 0;
}

.navbar ul li a {
  margin: 0;
  padding: 0;
  align-items: center;
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1rem;
}

.navbar ul li.active a {
  color: rgba(1,128,241,1);
  font-weight: bold;
}

.navbar ul li:hover a {
  color: rgba(1,128,241,1);
  font-weight: bold;
}

/***************************/
/* Headlines               */
/***************************/

.headlines {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  line-height: 1.5;
}

.headlines .loading {
  padding: 0 1rem;
  text-align: left;
  font-size: .9rem;
  color: black;
}

.headlines ul {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #f7f7f7;
}

.headlines ul li {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center; /* Align items vertically center */
  list-style: none; /* Remove default list styles */
}

.headlines ul li:nth-child(odd) {
  background: hsl(0, 0%, 93.33%)
}

.headlines ul li img {
  margin: 0.5rem;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0; /* Prevent image from shrinking */
}

.headlines ul li a {
  margin: 0.5rem;
  padding: 0;
  justify-content: space-between;
  color: rgba(1,128,241,1);
  font-size: .9rem;
  font-weight: bold;
  text-decoration: none;
  gap: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  overflow: hidden; /* Hide overflowed text */
}

.headlines ul li a:hover {
  background: #e3e3e3;
}

.headlines ul li p {
  margin: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  white-space: pre;
  color: rgba(1,128,241,1);
  font-weight: bold;
  text-decoration: none;
  gap: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}
